import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";

const SignUp = ({title, image, intro, subtitle, cta_1_text, cta_1_link}) => {
  return (
    <section className="signup container-fluid rounded-lg overflow-hidden pb-lg-5">
      <div className="container-fluid my-5 pt-lg-5">
        <div className="row no-gutters contained-xl justify-content-center px-4">
          <div className="col-lg-10 col-md-11 card rounded-lg overflow-hidden shadow">
            <div className="row contained-xl justify-content-center bg-black">
              <div className="order-lg-1 col-md-6 col-lg-5 p-0">
                <Link to={`https://join.baby-and-you.co.uk/register.html`}>
                  <Image
                    image={image}
                    className="img-fluid object-fit"
                    alt=""
                  />
                </Link>
              </div>
              <div className="order-lg-1 col-md-6 col-lg-7 p-0 align-self-center">
                <div className="py-4 px-5 p-md-5 ">
                  <h2 className="text-left font-family-display">
                    {title}
                  </h2>
                  <h3 className="h4 text-left font-family-display">
                    {subtitle}
                  </h3>
                  <p>
                    {intro}
                  </p>
                  <p className="mt-4 mt-lg-5">
                    <Link
                      to={cta_1_link}
                      className="btn btn-outline-white stretched-link"
                    >
                      {cta_1_text}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
